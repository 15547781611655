<template>
	<div class='classroom flex-align-center' id='dom' ref="classbox">
		<div class="slideshow flex-align-center" @touchstart='touchstart' @touchend='touchend'
			@mousedown="mousedown" @mouseup="mouseup">

			<div style="width:100%;margin:0 auto;height:100%">
				<slider ref="slider" :options="options" @slide='slide'>
					<slideritem v-for="(item,index) in numPages" :key="index" style="color:red;">
						<div class="Img flex-align-center">
							<pdf :src="pdfUrl" :page="index+1" v-if="videoIndex.indexOf(item) === -1">
							</pdf>
						</div>
						<!-- <MyVideo class="MyVideo" :index='item' v-if="item == videoIndex"
							:content="videoCode" ref="mychild" :valve='valve'></MyVideo> -->

						<MyVideo class="MyVideo" :index='item' v-if="videoIndex.indexOf(item) !== -1"
							:content="videoCode[videoIndex.indexOf(item)]" ref="mychild" :valve='valve'>
						</MyVideo>


					</slideritem>
					<div class="loading" slot="loading">loading...</div>
				</slider>
			</div>
		</div>
		<div class="process flex-align-between">
			<div>
				<!-- <div class="finish flex-align-between" @click="goback">
					<img src="@/assets/myclass/secedewhiteO.png">
					<span>下课</span>
				</div> -->
			</div>
			<div class="flex-between switchoverbox">
				<div class="switchover switchover1" @click="slidePre"></div>
				<div class="switchover switchover2" @click="slideNext"></div>
			</div>

		</div>
		<!-- scheduleShow -->
		<div class="loadbg flex-align" v-if="false">
			<div class="loadbgbox">
				<el-progress :percentage="schedule" color="#f1d277"></el-progress>
			</div>
		</div>

		<!-- 下课弹窗 -->
		<div class="classpop">
			<el-dialog title="下课提示" :visible.sync="classVisible" width="30%" center :modal="false">
				<div class="classpopBox flex-align-center">
					是否完成该课程学习？
				</div>
				<div class="popBtn flex-align-evenly">
					<div class="popBtn1" @click="finish">仅下课</div>
					<div class="popBtn2" @click="graduate">下课并标记已完成</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import html2canvas from "html2canvas";
	import MyVideo from './myVideo.vue';
	import {
		slider,
		slideritem
	} from "vue-concise-slider";
	import pdf from "vue-pdf";
	export default {
		components: {
			MyVideo,
			slider,
			slideritem,
			pdf
		},
		props: ['wordData'],
		data() {
			return {
				options: {
					currentPage: 0,
					freeze: false,
				},
				stepList: [],
				name: '',
				touchstartData: '',
				Time: '',
				num: 0,
				schedule: 0,
				scheduleShow: true,

				// 下课弹窗
				classVisible: false,
				wordId: '',
				classId: '',
				gradeId: '',
				termId: '',
				valve: '',
				imgNum: 0,
				numPages: '',
				pdfUrl: '',
				enclosure: '',
				// 视频索引
				videoIndex: [],
				videoCode: [],
			}
		},
		//方法
		methods: {
			mousedown(e) {
				this.touchstartData = e.screenX;
				this.Time = new Date().getTime();
			},
			mouseup(e) {
				let date = new Date().getTime();
				if (date - this.Time > 600) {
					if (this.touchstartData - e.screenX > 200) {
						this.slideNext()
					} else if (e.screenX - this.touchstartData > 200) {
						this.slidePre()
					}
				}
			},
			touchstart(e) {
				this.touchstartData = e.targetTouches[0].screenX;
				this.Time = new Date().getTime()
			},
			touchend(e) {
				let date = new Date().getTime();
				let that = this;
				if (date - that.Time > 600) {
					if (that.touchstartData - e.changedTouches[0].screenX > 200) {
						that.slideNext()
					} else if (e.changedTouches[0].screenX - that.touchstartData > 200) {
						that.slidePre()
					}
				}
			},
			//检测索引
			slide(data, e) {
				this.valve = data.currentPage;
			},
			//下一页
			slideNext() {
				this.$refs.slider.$emit('slideNext');
			},
			//上一页
			slidePre() {
				this.$refs.slider.$emit('slidePre');
			},

			goback() {
				this.classVisible = true;
			},

			onload() {
				this.num++;
				this.schedule = parseInt(Number(this.num) / Number(this.imgNum) * 100);
				if (this.schedule == 100) return this.scheduleShow = false;
			},

			finish() {
				this.classVisible = false;
				this.$router.go(-1);
			},
			async graduate() {
				this.classVisible = false;
				if (this.$route.params.type == 1) {
					let data = {
						taskId: this.$route.query.taskId,
						// classId: this.$route.query.classId,
						classId: this.wordData.classId,
					}
					let resData = await this.$Api.Myclass.classLearnedTask(data);
				} else {
					let data = {
						// wordId: this.$route.query.wordId,
						wordId: this.wordData.wordId,
						classId: this.classId,
						gradeId: 1
					}
					let resData = await this.$Api.Myclass.postLearnedWord(data);


				}
				this.$router.go(-1);
			},
			async getStepdata() {
				let data = {
					// wordId: this.$route.query.wordId
					wordId: this.wordData.wordId,
				}
				let resData = await this.$Api.Myclass.getImgList(data);
				// console.log('resData-------------', resData)
				this.enclosure = resData.data.enclosure;
				this.stepList = resData.data.videoCodeList;
				for (let i = 0; i < this.stepList.length; i++) {
					this.videoIndex.push(this.stepList[i].index);
					this.videoCode.push(this.stepList[i].content);

				}
				// for (let i = 0; i < this.stepList.length; i++) {
				// 	if (this.stepList[i].type != 2) {
				// 		this.imgNum++;
				// 	}
				// }
				this.getNumPages();


			},
			getNumPages() {
				let loadingTask = pdf.createLoadingTask(this.enclosure);
				// let loadingTask = pdf.createLoadingTask('/san.pdf');
				loadingTask.promise.then(pdf => {

					this.pdfUrl = loadingTask;
					this.numPages = pdf.numPages;
					// console.log('this.numPages', this.numPages)
				}).catch(err => {
					console.error('pdf 加载失败', err);
				})
			},


		},

		//生命周期 - 创建完成（访问当前this实例）
		created() {
			let that = this;
			document.onkeydown = function (e) {
				let key = window.event.keyCode;

				if (key == 13 || key == 40 || key == 39) {
					that.slideNext();
				} else if (key == 38 || key == 37) {
					that.slidePre()
				} else if (key == 32) {
					let dom = document.getElementsByTagName('video');
					for (let i = 0; i < dom.length; i++) {
						dom[i].id = 'dom' + 1;
					}
					let dom1 = document.getElementById('dom1');

					let dom2 = document.getElementById('dom1');
					if (that.valve == 3) {
						if (dom1.paused) {
							dom1.play();
							dom2.pause();
						} else if (dom1.play()) {
							dom1.pause();
							dom2.play();
						}
					} else if (that.valve == 6) {
						if (dom2.paused) {
							dom2.play();
							dom1.pause();
						} else if (dom2.play()) {
							dom2.pause();
							dom1.play();
						}
					}
				}
			}
		},
		//生命周期 - 挂载完成（访问DOM元素）
		mounted() {
			this.getStepdata();
		}
	}
</script>
<style lang='less' scoped>
	@import './less/classroom';
</style>