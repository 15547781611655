<!--  -->
<template>
      <div class='courseB flex-align-center' ref="courseB">
            <div class="courseVideo" :id="'player'+index"></div>
      </div>
</template>

<script>
      import PubSub from 'pubsub-js'
      export default {
            props: ["index", "content", "valve"],
            data() {
                  return {
                        player: '',
                        num: 0
                  }
            },
            //方法
            methods: {
                  async loadPlayer() {
                        let params = {
                              vid: this.content
                        };
                        let that = this;
                        this.$Api.Home.getVid(params).then(res => {
                              let data = res.data.playsafe;
                              this.player = new polyvPlayer({
                                    wrap: '#player' + this.index,
                                    vid: this.content,
                                    'loop': false,
                                    'autoplay': false,
                                    // 'forceH5': true,
                                    'playsafe': data,
                                    'hideSwitchPlayer':true
                              });
                              //播放
                              that.player.on('s2j_onVideoPlay', that.attplay);
                              //暂停
                              that.player.on('s2j_onVideoPause', that.attpause);
                              //全屏切换
                              // that.player.on('s2j_onFullScreen', that.FullScreen);
                              // that.player.on('s2j_onNormalScreen', that.FullScreen);
            
                        }).catch(err => {

                        })
                  },
                  FullScreen() {
                        if (this.num == 0) {
                              // this.player.toggleFullscreen();
                              this.num = 1;
                        }

                  },
                  attplay() {
                        // console.log('视频开始播放')
                  },
                  attpause() {
                        // console.log('视频暂停播放')
                  },

                  pauseVideo() {
                        this.player.j2s_pauseVideo();
                  },
                  parentHandleclick(e) {
                        // console.log('e', e)
                  },
            },
            //生命周期 - 挂载完成（访问DOM元素）
            created() {

            },
            mounted() {
                  this.loadPlayer();
                  // console.log('index',this.index)
                  // console.log('content',this.content)
                  // console.log('valve',this.valve)
                  // this.loadPlayer().then(res => {
                  //       PubSub.subscribe('myVideo_pauseVideo', (msg, data) => {
                  //             // this.pauseVideo();
                  //       });
                  // });

            },
            watch: {
                  valve(newval, oldval) {
                        let that = this;
                        setTimeout(() => {
                              that.player.j2s_pauseVideo();
                        }, 300)
                  }
            }
      }
</script>
<style lang='less' scoped>
      @import './less/myVideo';
</style>