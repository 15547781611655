<!--  -->
<template>
      <div class=''>
            <div class="FlipClock">
                  <Flipper ref="flipperHour1"></Flipper>
                  <!-- 9 -->
                  <Flipper ref="flipperHour2"></Flipper>
                  <em>:</em>
                  <!-- 5 -->
                  <Flipper ref="flipperMinute1"></Flipper>
                  <!-- 9 -->
                  <Flipper ref="flipperMinute2"></Flipper>
                  <em>:</em>
                  <!-- 5 -->
                  <Flipper ref="flipperSecond1"></Flipper>
                  <!-- 9 -->
                  <Flipper ref="flipperSecond2"></Flipper>
            </div>
      </div>
</template>

<script>
      import Flipper from './Flipper.vue';
      export default {
            data() {
                  return {
                        timer: null,
                        flipObjs: [],
                        hour: "", //倒计时  小时
                        minute: "", // 倒计时 分钟
                        seconds: "", //倒计时 秒
                        interval: "",
                        countdownTime: 1,
                        timeA: '',
                        timeB: '',
                        type: '',
                        theFront: true,
                        // 正计时
                        judgeTime: '',
                        delayer: '',
                        input: '',
                        num: 0,
                        suspendValve: '',
                  }
            },
            components: {
                  Flipper

            },
            //方法
            methods: {


                  time1(value, type) {
                          console.log('开始执行')
                        clearTimeout(this.delayer);
                        clearInterval(this.interval);
                        if (this.num != 1) {
                              this.num++
                        }
                        this.type = type;
                        let time = type == 's' ? value : value * 60 * 1000;
                        this.num == 1 ? this.decorate1(time, type) : this.decorate2(time, type);
                  },
                  decorate1(time, type) {
                        if (this.theFront) {
                              // 正计时
                              setTimeout(() => {
                                    type == 's' ? this.transmit(this.dispose(0)) : this.setTimeData(0);
                                    this.countdownTime = 0;
                                    this.judgeTime = time;
                              }, 600)

                        } else {
                              //倒计时
                              setTimeout(() => {
                                    type == 's' ? this.transmit(this.dispose(time)) : this.setTimeData(
                                          time);
                                    this.countdownTime = time;
                              }, 600)

                        };
                  },
                  decorate2(type) {
                        if (this.theFront) {
                              // 正计时
                              type == 's' ? this.transmit(this.dispose(0)) : this.setTimeData(0);
                              this.countdownTime = 0;
                              this.judgeTime = time;
                        } else {
                              //倒计时
                              type == 's' ? this.transmit(this.dispose(time)) : this.setTimeData(
                                    time);
                              this.countdownTime = time;
                        };
                  },
                  // 数字初始化
                  setTimeData(cm) {
                        this.hour = Math.floor((cm / 1000 / 60 / 60) % 24);
                        this.hour =
                              this.hour.toString().length == 1 ? "0" + this.hour : this.hour;
                        this.minute = Math.floor((cm / 1000 / 60) % 60);
                        this.minute =
                              this.minute.toString().length == 1 ? "0" + this.minute : this.minute;
                        this.seconds = Math.floor((cm / 1000) % 60);
                        this.seconds =
                              this.seconds.toString().length == 1 ?
                              "0" + this.seconds :
                              this.seconds;
                        this.transmit(this.hour + this.minute + this.seconds);
                  },
                  transmit(data) {
                        for (let i = 0; i < this.flipObjs.length; i++) {
                              this.flipObjs[i].setFront(data[i])
                        }
                  },
                  //开始执行
                  statr() {
                       
                        clearInterval(this.interval);
                        clearTimeout(this.delayer);
                        let _this = this;

                        this.interval = setInterval(function () {
                              _this.type == 's' ? _this.secondsdown(_this
                                    .countdownTime) : _this.payCountdown(
                                    _this.countdownTime);

                              clearInterval(_this.interval);
                        }, 1000);
                  },
                  //暂停
                  suspend() {
                        clearInterval(this.interval);
                        clearTimeout(this.delayer);
                        this.countdownTime = this.suspendValve;
                  },
                  // 倒计时
                  setTimer() {
                        clearInterval(this.interval);
                        clearTimeout(this.delayer);
                        setTimeout(() => {
                              this.transmit('000000');
                              this.suspendValve = '';
                        }, 600)

                        this.theFront = false;
                  },
                  // 正计时
                  setTimer2() {
                        clearInterval(this.interval);
                        clearTimeout(this.delayer);
                        setTimeout(() => {
                              this.transmit('000000');
                              this.suspendValve = '';
                        }, 600)
                        this.theFront = true;
                  },

                  // 分钟递减数据
                  payCountdown(cm) {
                        if (this.theFront ? cm < this.judgeTime : cm >= 0) {
                              this.hour = Math.floor((cm / 1000 / 60 / 60) % 24);
                              this.hour =
                                    this.hour.toString().length == 1 ? "0" + this.hour : this.hour;
                              this.minute = Math.floor((cm / 1000 / 60) % 60);
                              this.minute =
                                    this.minute.toString().length == 1 ?
                                    "0" + this.minute :
                                    this.minute;
                              this.seconds = Math.floor((cm / 1000) % 60);
                              this.seconds =
                                    this.seconds.toString().length == 1 ?
                                    "0" + this.seconds :
                                    this.seconds;
                              let _msThis = this;
                              _msThis.delayer = setTimeout(function () {
                                    _msThis.timeA = cm;
                                    let nowTimeStr = _msThis.wordProcessing(_msThis.timeA);
                                    // console.log('分钟递减数据nowTimeStr', nowTimeStr)
                                    _msThis.theFront ? cm += 1000 : cm -= 1000;
                                    _msThis.timeB = cm;
                                    let nextTimeStr = _msThis.wordProcessing(_msThis.timeB);
                                    // console.log('分钟递减数据nextTimeStr', nextTimeStr)
                                    _msThis.payCountdown(cm);
                                    if (cm >= 0) {
                                          // console.log('分钟递减数据cm', cm)
                                          _msThis.suspendValve = cm;
                                          for (let i = 0; i < _msThis.flipObjs.length; i++) {
                                                if (nowTimeStr[i] === nextTimeStr[i]) {
                                                      continue
                                                }
                                                _msThis.flipObjs[i].flipDown(
                                                      nowTimeStr[i],
                                                      nextTimeStr[i]
                                                )
                                          }
                                    }
                              }, 1000);
                        } else {
                              this.$emit('childFn', false);
                        }
                  },
                  //秒递减数据
                  secondsdown(cm) {
                        if (this.theFront ? cm < this.judgeTime : cm >= 0) {
                              let _msThis = this;
                              _msThis.delayer = setTimeout(function () {
                                    let nowTimeStr = _msThis.dispose(cm);
                                    // console.log('秒递减数据nowTimeStr', nowTimeStr)
                                    _msThis.theFront ? cm++ : cm--;
                                    let nextTimeStr = _msThis.dispose(cm);
                                    // console.log('秒递减数据nextTimeStr', nextTimeStr)
                                    _msThis.secondsdown(cm);
                                    if (cm >= 0) {
                                          // console.log('秒递减数据cm', cm)
                                          _msThis.suspendValve = cm;
                                          for (let i = 0; i < _msThis.flipObjs.length; i++) {
                                                if (nowTimeStr[i] === nextTimeStr[i]) {
                                                      continue
                                                }
                                                _msThis.flipObjs[i].flipDown(
                                                      nowTimeStr[i],
                                                      nextTimeStr[i]
                                                )
                                          }
                                    }
                              }, 1000)
                        }else {
                              this.$emit('childFn', false);
                        }



                  },
                  // 分钟
                  wordProcessing(cm) {
                        let data = 0;
                        if (cm >= 0) {
                              let hour = Math.floor((cm / 1000 / 60 / 60) % 24);
                              hour = hour.toString().length == 1 ? "0" + hour : hour;
                              let minute = Math.floor((cm / 1000 / 60) % 60);
                              minute = minute.toString().length == 1 ? "0" + minute : minute;
                              let seconds = Math.floor((cm / 1000) % 60);
                              seconds = seconds.toString().length == 1 ? "0" + seconds : seconds;
                              data = hour + minute + seconds;
                              return data;
                        }
                        return data;
                  },
                  // 秒补0
                  dispose(cm) {
                        if (cm < 0) return '000000';
                        if (/^\d{2}$/.test(parseInt(cm))) {
                              return '0000' + cm
                        } else {
                              return '00000' + cm
                        }

                  }
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {
                  // console.log('分钟递减数据给父级传参')
                  // this.$emit('TimeWidget', 'true');
            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.flipObjs = [
                        this.$refs.flipperHour1,
                        this.$refs.flipperHour2,
                        this.$refs.flipperMinute1,
                        this.$refs.flipperMinute2,
                        this.$refs.flipperSecond1,
                        this.$refs.flipperSecond2
                  ]
            },
            // watch: {
            //       suspendValve(newVal, oldVal) {
            //             console.log('newVal',newVal)
            //             console.log('oldVal',oldVal)
            //       }
            // }
      }
</script>
<style lang='less' scoped>
      @import './less/timeWidget';
</style>