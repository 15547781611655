<template>
      <div class='myTimes'>
            <el-dialog :visible.sync="centerDialogVisible" width="30%" center :show-close="false"
                  :modal-append-to-body='false' @close="myTimesclose">
                  <div class="myTimesBg flex-column-center">
                        <div class="switchbox">
                              <div class="switch1 flex-align-center" :class="{switchbg:switchStatus}"
                                    @click="switchChange(1)">倒计时</div>
                              <div class="switch2 flex-align-center" :class="{switchbg:!switchStatus}"
                                    @click="switchChange(2)">正计时</div>
                              <div class="switchT" :class="{switchT1:!switchStatus}"></div>
                              <!-- :class="switchbg?'switchT2':'switchT1'" -->
                              <!-- <el-switch v-model="switchStatus" active-text="倒计时" inactive-text="正计时" @change="switchChange">
                        </el-switch> -->
                        </div>

                        <TimeWidget ref="Widget" @childFn="parentFn"></TimeWidget>
                        <div class="line"></div>
                        <div class="controlBtn">
                              <div class="timebtn flex-align-around">
                                    <el-button size="small" @click="transmit('30','s')"
                                          :class="{newBtn:colorIndex === '30'}">30s</el-button>
                                    <el-button size="small" @click="transmit('1','m'),colorIndex = '66'"
                                          :class="{newBtn:colorIndex === '66'}">1分钟</el-button>
                                    <el-button size="small" @click="transmit('2','m')"
                                          :class="{newBtn:colorIndex === '2'}">2分钟</el-button>
                                    <el-button size="small" @click="transmit('3','m')"
                                          :class="{newBtn:colorIndex === '3'}">3分钟</el-button>
                              </div>
                              <div class="timebtn flex-align-around">
                                    <el-button size="small" @click="transmit('5','m')"
                                          :class="{newBtn:colorIndex === '5'}">5分钟</el-button>
                                    <el-button size="small" @click="transmit('10','m')"
                                          :class="{newBtn:colorIndex === '10'}">10分钟</el-button>
                                    <el-button size="small" @click="transmit('15','m')"
                                          :class="{newBtn:colorIndex === '15'}">15分钟</el-button>
                                    <el-popover placement="left" width="160" v-model="visible"
                                          @show="colorIndex = '99'">
                                          <div class="inputBox">
                                                <el-row>
                                                      <el-input v-model="secondValue" placeholder="秒" type='number'
                                                            @focus='secondFocus'>
                                                      </el-input>
                                                      <!-- <el-input v-model="minuteValue" placeholder="分钟"
                                                            @focus='minuteFocus'>
                                                      </el-input> -->
                                                </el-row>
                                          </div>
                                          <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visible = false">取消
                                                </el-button>
                                                <el-button type="primary" size="mini" @click="customization">确定
                                                </el-button>
                                          </div>
                                          <el-button size="small" slot="reference"
                                                :class="{newBtn:colorIndex === '99'}">
                                                自定义</el-button>
                                    </el-popover>

                              </div>
                              <div class="statrBtn flex-align-center">
                                    <!-- <el-tooltip class="item" effect="light" content="请选择时间" placement="top" v-model='tooltip'> -->
                                    <el-button :icon="iconType?'el-icon-video-pause':'el-icon-video-play'"
                                          @click="startTime"></el-button>
                                    <!-- </el-tooltip> -->

                              </div>
                        </div>
                        <div class="rightControl">
                              <i class="el-icon-minus" @click="centerDialogVisible = false"></i>
                              <i class="el-icon-close" @click="centerDialogVisible = false"></i>
                        </div>
                  </div>
            </el-dialog>
      </div>
</template>

<script>
      import TimeWidget from '../assistant/common/timeWidget.vue'
      export default {
            components: {
                  TimeWidget,
            },
            props: {
                  TimeType: {
                        type: Boolean,
                        default: false,
                  },
                  setBillDetail: {
                        type: Function,
                        default: null,
                  },
            },
            data() {
                  return {
                        secondValue: '',
                        minuteValue: '',
                        centerDialogVisible: false,
                        switchStatus: false,
                        iconType: false,
                        visible: false,
                        // hint: false,
                        time: '',
                        timeType: '',
                        tooltip: false,
                        switchbg: false,
                        colorIndex: null,
                  }
            },
            //方法
            methods: {
                  //计时器
                  switchChange(value) {
                        this.colorIndex = null;
                        this.switchStatus = value == 1 ? true : false;
                        this.iconType = false;
                        this.switchStatus ? this.$refs.Widget.setTimer() : this.$refs.Widget.setTimer2()
                  },
                  transmit(value, type) {
                        console.log('value', value)
                        this.colorIndex = value;
                        this.time = value;
                        this.timeType = type;
                        this.$refs.Widget.time1(this.time, this.timeType);
                        this.iconType = false;
                  },
                  startTime() {
                        // console.log('this.time',this.time)
                        // console.log('this.minuteValue',this.minuteValue)
                        // console.log('this.secondValue',this.secondValue)
                        //  && this.minuteValue != '' || this.secondValue
                        if (this.time != '') {

                              this.iconType = !this.iconType;
                              this.iconType ? this.$refs.Widget.statr() : this.$refs.Widget.suspend();
                              this.tooltip = false;
                        } else {
                              alert('请选择时间')
                              // this.tooltip = true;
                        }
                  },
                  secondFocus() {
                        this.minuteValue = '';

                  },
                  minuteFocus() {
                        this.secondValue = '';
                  },
                  customization() {
                        console.log('this.colorIndex', this.colorIndex)
                        this.colorIndex = '99';
                        if (this.minuteValue != '') {
                              this.transmit(this.minuteValue, 'm')
                        }
                        if (this.secondValue != '') {
                              if (this.secondValue > 60) {
                                    this.secondValue = this.secondValue / 60;
                                    this.transmit(this.secondValue, 'm')
                              } else {
                                    this.transmit(this.secondValue, 's')
                              }

                        }
                        this.visible = false;
                  },
                  parentFn(value) {
                        this.iconType = value;
                        // console.log('this.iconType', this.iconType)
                        if (this.iconType == false) {
                              this.time = '';
                              this.colorIndex = null;
                        }
                  },
                  myTimesclose() {
                        this.$emit('setBillDetail')
                  }
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {

            },
      }
</script>
<style lang='less' scoped>
      @import './less/myTimes';
</style>